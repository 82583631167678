var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DecodeImage",
    {
      staticClass: "common-page-content",
      attrs: { path: _vm.backgroundImg, "background-image-mode": true }
    },
    [
      _c(
        "div",
        { staticClass: "tutorial-logo" },
        [
          _vm._t("logo", [
            _c("DecodeImage", {
              staticClass: "logo",
              attrs: { path: _vm.logoImg }
            })
          ])
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "tutorial-title" },
        [
          _vm._t("title", [
            _c("div", { staticClass: "common-page-title" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          ])
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "tutorial-steps" },
        [_vm._t("steps", [_c("Steps")])],
        2
      ),
      _c(
        "div",
        { staticClass: "tutorial-download" },
        [
          _vm._t("download", [
            _c(
              "div",
              { staticClass: "brand-common-btn", on: { click: _vm.download } },
              [_vm._v("知道了，立即下载")]
            )
          ])
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "tutorial-join-group" },
        [
          _vm._t("join-group", [
            _c("JoinGroup", { attrs: { "app-code": _vm.appCode } })
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }