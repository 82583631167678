import { isPC } from "../utils";

const userAgent = navigator.userAgent;
export const IS_ANDROID =
  userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1; //android终端
export const IS_iOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
export const IS_PC = isPC();
export const IS_TENCENT_ENV = 
  userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger" // 微信
  || userAgent.toLowerCase().indexOf("weibo") > -1 // 微博
  || userAgent.toLowerCase().indexOf("tieba") > -1 // 貼吧

export const IOS_TF = "iOS_tf";
export const IOS_H5 = "iOS_h5";
export const IOS_LIGHT = 'iOS_light';
export const ANDROID = "Android";
export const GO_TO_DOWNLOAD_PAGE = "goto_download_page";
export const CLICK_DOWNLOAD = "click_download";
export const COPY_SUCCESS = "copy_success";
export const GET_DOWNLOAD_URL = "get_download_url";

export const UV_COOKIE_NAME = "a_x_u_seri_den_t_if_ier";

/** ----------------------------------------------------------------------- */


// 以下内容需要替换
export const ENV = process.env.VUE_APP_ENV
export const API_PREFIX = process.env.VUE_APP_API_PREFIX
export const API_HOST = process.env.VUE_APP_API_HOST
export const API_EVENT_HOST = process.env.VUE_APP_API_EVENT_PREFIX
export const STATISTICS_HOST = process.env.VUE_APP_STATISTICS_HOST
export const SENTRY_ENVIRONMENT = process.env.VUE_APP_SENTRY_ENVIRONMENT
export const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN
export const OPEN_B = process.env.VUE_APP_OPEN_B || false
export const WARN_TEXT = "成人内容";
export const FUNNY_VIDEO = "部国产成人短视频";
export const CITY_DATE = "城市线下约会";
export const ONLINE_CHAT = "妹子在线聊骚";
export const ANDROID_WARN = "成人应用被误报病毒，我们靠广告盈利，不是病毒！";
export const IOS_WARN = "受苹果政策影响，APP无法上架苹果商店，请按教程安装";

export const CHAT_GROUP_URL = "https://dlptm.org/fuli2chun";

export const DECODE_MEDIA_KEY = "Jui7X#c9HzN^3eZb";

export const IOS_DEFAULT_TITPS = "下载后图标非91视频，开启后即可呈现91视频内容"
export const IOS_DEFAULT = "iOS_default";

import Vue from 'vue'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  integrations: [
    new BrowserTracing({}),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
