var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.backgroundImageMode
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.src,
              expression: "src"
            }
          ],
          style: { backgroundImage: "url(" + _vm.src + ")" }
        },
        [_vm._t("default")],
        2
      )
    : _c("img", {
        directives: [
          { name: "show", rawName: "v-show", value: _vm.src, expression: "src" }
        ],
        attrs: { src: _vm.src, alt: "" }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }