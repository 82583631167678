var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tencent_content" },
    [
      _c("decode-image", {
        attrs: { path: require("@/assets/images/tencent_1.png") }
      }),
      _c("div", { staticClass: "tencent_tips_option" }, [
        _c("div", { staticClass: "ac_flex" }, [
          _c("span", { staticClass: "option_two jac_flex" }, [_vm._v("2")]),
          _c("div", { staticClass: "domain_box" }, [
            _c("p", [_vm._v("或复制链接到浏览器打开：")]),
            _c("p", { staticClass: "domain" }, [_vm._v(_vm._s(_vm.url))])
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "copy_domain jac_flex",
            attrs: { id: "js_copy_domain" },
            on: { click: _vm.copyLink }
          },
          [_vm._v(" 点击复制链接 ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }