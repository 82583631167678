import { setUserIdentifier } from "../utils";
import { Version } from '../config/version'
require("./assets/js/fetch.min");
setUserIdentifier();
window.onload = () => {
  document.addEventListener(
    "touchstart",
    event => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    },
    { passive: false }
  );

  let lastTouchEnd = 0;
  document.addEventListener(
    "touchend",
    event => {
      const now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    false
  );
};

// 在window上挂载版本号
window.appVersion = Version;
window.console.log(`Version:${window.appVersion}`);
