var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DecodeImage",
    {
      staticClass: "common-page-content",
      attrs: { path: _vm.backgroundImg, "background-image-mode": true }
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "solution-logo" },
          [
            _vm._t("logo", [
              _c("DecodeImage", {
                staticClass: "logo",
                attrs: { path: _vm.logoImg }
              })
            ])
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "solution-title" },
          [
            _vm._t("title", [
              _c("div", { staticClass: "common-title" }, [
                _vm._v(_vm._s(_vm.title))
              ])
            ])
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "solution-phone-types" },
          [
            _vm._t(
              "phone-types",
              _vm._l(_vm.phoneTypes, function(type) {
                return _c(
                  "div",
                  {
                    key: type.name,
                    on: {
                      click: function($event) {
                        return _vm.clickShowPopup(type.src)
                      }
                    }
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "brand-common-btn",
                        attrs: { "data-cy": "mobile-tutorial" }
                      },
                      [_vm._v(" " + _vm._s(type.name) + "手机教程 ")]
                    )
                  ]
                )
              })
            )
          ],
          2
        )
      ]),
      _c(
        "div",
        { staticClass: "solution-join-group" },
        [
          _vm._t("join-group", [
            _c("p", { staticClass: "tips" }, [_vm._v(_vm._s(_vm.tipsText))]),
            _c(
              "a",
              {
                attrs: {
                  href: _vm.CHAT_GROUP_URL,
                  target: "_blank",
                  "data-cy": "join-group-link"
                }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "brand-common-btn",
                    on: { click: _vm.onJoinClick }
                  },
                  [_vm._v(" 点击加入 ")]
                )
              ]
            )
          ])
        ],
        2
      ),
      _c(
        "van-popup",
        {
          style: { width: "90%" },
          attrs: { "get-container": "#app", "data-cy": "mask" },
          model: {
            value: _vm.showPopup,
            callback: function($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup"
          }
        },
        [
          _c("DecodeImage", {
            attrs: { path: _vm.popupImg },
            nativeOn: {
              click: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "t", undefined, $event.key, undefined)
                ) {
                  return null
                }
                $event.stopPropagation()
                return _vm.clickClosePopup($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }