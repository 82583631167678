var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "download_tips_page" },
    [
      _c("p", [_vm._v("下载")]),
      _vm._m(0),
      _c("DecodeImage", {
        staticClass: "risk_warning",
        attrs: { path: _vm.riskWarningImg }
      }),
      _c("p", { staticClass: "select" }, [_vm._v('选择"继续下载"')]),
      _c("p", [_vm._v("安装")]),
      _vm._m(1),
      _c("DecodeImage", {
        staticClass: "risk_warning",
        attrs: { path: _vm.riskWarningImg }
      }),
      _c("p", { staticClass: "select" }, [_vm._v('选择"允许"')])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "font_size_18" }, [
      _c("span", { staticClass: "step" }, [_vm._v("1")]),
      _c("span", [_vm._v("点击下载后，提示文件存在风险")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "font_size_18" }, [
      _c("span", { staticClass: "step" }, [_vm._v("2")]),
      _c("span", [_vm._v("安装时，提示未知来源应用")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }