var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wrapper",
      attrs: { "data-cy": "mask" },
      on: {
        click: function($event) {
          return _vm.$emit("hide")
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "kind_titps",
          on: {
            click: function($event) {
              $event.stopPropagation()
            }
          }
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.iosTips))]),
          _c(
            "div",
            { staticClass: "logo_wrapper" },
            [
              _c("img", {
                staticClass: "logo",
                attrs: { src: _vm.appLogoIcon, alt: "" }
              }),
              _c("img", {
                staticClass: "union",
                attrs: {
                  src: require("@/assets/images/apple_union.png"),
                  alt: ""
                }
              }),
              _c("DecodeImage", {
                staticClass: "logo",
                attrs: { path: _vm.logoIcon }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "brand-common-btn",
              attrs: { "data-cy": "go-to-app-store" },
              on: {
                click: function($event) {
                  return _vm.$emit("btnClick")
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/apple_logo_white.png"),
                  alt: ""
                }
              }),
              _c("span", [_vm._v("前往苹果商店")])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }