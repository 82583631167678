var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DecodeImage",
    {
      staticClass: "common-page-content",
      attrs: {
        "data-cy": "page-content",
        "background-image-mode": true,
        path: _vm.backgroundImg
      }
    },
    [
      _c(
        "div",
        { staticClass: "tutorial-logo" },
        [
          _vm._t("logo", [
            _c("DecodeImage", {
              staticClass: "logo",
              attrs: { path: _vm.logoImg }
            })
          ])
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "tutorial-title" },
        [
          _vm._t("title", [
            _c("div", { staticClass: "common-page-title" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          ])
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "tutorial-download" },
        [
          _vm._t("download", [
            _c(
              "div",
              {
                staticClass: "brand-common-btn",
                attrs: { "data-cy": "download-button" },
                on: { click: _vm.download }
              },
              [_vm._v(" 立即下载 ")]
            )
          ])
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "tutorial-steps" },
        [
          _vm._t("steps", [
            _c("DecodeImage", {
              staticClass: "step",
              attrs: { path: _vm.stepImg }
            })
          ])
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "tutorial-join-group" },
        [
          _vm._t("join-group", [
            _c("JoinGroup", { attrs: { "app-code": _vm.appCode } })
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }